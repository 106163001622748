<template>
	<div :id="`calendar__element__${slug}`" class="calendar" :class="{ preview: preview }">
		<div class="calendar__day-slots">
			<div class="w-100 text-center calendar__title">{{ selectTitle }}</div>
			<div class="w-100 text-center calendar__timezone">{{ timezone }}</div>
			<div class="d-flex calendar__navigator w-100">
				<button class="px-2 font-size" :disabled="prevDisabled || isLoading" @click="previousMonth">
					<svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M14 1L2 12L14 23" stroke="#000C2D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</button>
				<div v-if="step === 1" class="calendar__subtitle month">{{ currentMonth }}</div>
				<div v-if="step === 2" class="calendar__subtitle duration">
					<strong>{{ selectedDate }}</strong> ({{
						meeting && meeting.data && meeting.data.duration_minutes ? meeting.data.duration_minutes : 30
					}}
					min)
				</div>
				<div v-if="step === 3" class="calendar__subtitle final-time slide-in-fwd-bottom">{{ finalTime }}</div>
				<button :disabled="isLoading" class="px-2 font-size" @click="nextMonth">
					<svg
						v-bind:style="{ visibility: step === 1 ? 'visible' : 'hidden' }"
						width="16"
						height="24"
						viewBox="0 0 16 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M2 23L14 12L2 1" stroke="#000C2D" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
					</svg>
				</button>
			</div>
			<div class="w-100">
				<div v-if="!isLoading" class="calendar__wrapper">
					<FlatPickr v-if="step === 1" v-model="valueDate" key="flatcalendar" :config="config" @on-change="selectDate" />
				</div>
				<div v-if="isLoading" class="calendar__loader" style="transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms">
					<HollowDotsSpinner :animation-duration="1000" :dot-size="12" :dots-num="3" :color="primaryColor" />
				</div>
			</div>
		</div>
		<div v-if="!isLoading && step === 2" class="calendar__time-slots" :class="isDemo ? 'preview-time-slots' : ''">
			<div v-for="(slot, idx) in availableSlot" :key="`slot-${idx}`" class="calendar__time-slot">
				<div
					class="calendar__time-slot-button"
					:class="{ selected: activeTime !== null && activeTime.startDateTime === slot.startDateTime }"
					@click="selectTime(slot)"
				>
					<div class="calendar__time">{{ slot.stime }} {{ slot.ampm }}</div>
				</div>
			</div>
			<p v-if="!availableSlot || availableSlot.length === 0" class="w-100 mt-3" align="center">
				{{ $t('shared.calendar.no_available_times') }}
			</p>
		</div>
	</div>
</template>
<script>
	import moment from 'moment'
	import HollowDotsSpinner from './HollowDotsSpinner'
	import Il8nMixin from './Il8nMixin'
	import { unsupportedTimezoneMap } from './utils'

	import FlatPickr from 'vue-flatpickr-component'
	import 'flatpickr/dist/flatpickr.css'

	export default {
		name: 'Calendar',
		mixins: [Il8nMixin],
		components: {
			// BCalendar,
			HollowDotsSpinner,
			FlatPickr,
		},
		props: {
			slug: { type: String, default: '' },
			username: {
				type: String,
				default: '',
			},
			primaryColor: { type: String, default: '#5383ff' },
			isDemo: Boolean,
			apiBaseUrl: { type: String, default: process.env.NUXT_ENV_PYTHON_API },
			availability: {
				type: Object,
				default: () => {},
			},
			preview: { type: Boolean },
			preSelectedDate: { type: String },
		},
		emits: ['date-selected', 'time-selected'],
		data() {
			return {
				meeting: null,
				loading: false,
				weekDatesRange: [],
				datesInMonthAavailable: {},
				availableSlot: [],
				activeDate: null,
				firstLoad: null,
				activeTime: null,
				valueDate: '',
				step: 1,
				dateFormat: 'YYYY-MM-DD',
				months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
				config: {
					disable: [this.dateDisabled],
					locale: {
						firstDayOfWeek: 1, // start week on Monday
					},
					inline: true,
					onDayCreate: this.onDayCreate,
					defaultDate: null,
					disableMobile: true,
				},
			}
		},
		computed: {
			isLoading() {
				return this.loading
			},
			currentDate() {
				return moment()
			},
			timezone() {
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
				return unsupportedTimezoneMap[timezone] || timezone
			},
			currentMonth() {
				if (!this.valueDate) {
					const date = this.currentDate
					return this.months[date.month()] + ' ' + date.year()
				}
				const date = moment(this.valueDate)
				return this.months[date.month()] + ' ' + date.year()
			},
			selectedDate() {
				const date = moment(this.valueDate)
				return date.format('ddd') + ', ' + this.months[date.month()] + ' ' + date.date() + ', ' + date.year()
			},
			finalTime() {
				const date = moment(this.valueDate)
				return this.months[date.month()] + ' ' + date.date() + ', ' + this.activeTime.startTime
			},
			prevDisabled() {
				if (this.step === 1) {
					const date = this.valueDate ? moment(this.valueDate) : moment()
					let d = new Date()
					d = new Date(d.setMonth(d.getMonth(), 1))
					d = d.setHours(0, 0, 0, 0)
					let newDate = new Date(date.month(date.month() - 1, 1))
					newDate = newDate.setHours(0, 0, 0, 0)
					return newDate < d
				} else {
					return false
				}
			},
			selectTitle() {
				switch (this.step) {
					case 1:
						return this.$t('shared.calendar.choose_date')
					case 2:
						return this.$t('shared.calendar.choose_time')
					case 3:
					default:
						return this.$t('shared.calendar.selected_date_time')
				}
			},
		},
		async mounted() {
			await this.reset()
		},
		methods: {
			async reset() {
				// Clear all current data
				this.weekDatesRange = []
				this.datesInMonthAavailable = {}
				this.availableSlot = []
				this.activeTime = null
				this.valueDate = ''
				this.step = 1
				this.meeting = null
				this.activeDate = null
				this.firstLoad = null

				// Loads base meeting data
				if (!this.isDemo) await this.fetchData()

				this.selectDate([this.currentDate.format(this.dateFormat)])
				this.$emit('initialized')

				// Fetch calendar availabitly from server if not demo
				if (this.preSelectedDate) {
					await this.getMonthAvailableTimeSlots(this.preSelectedDate)
				} else {
					await this.getMonthAvailableTimeSlots(this.currentDate.format(this.dateFormat))
				}

				const currentMonthDates = Object.keys(this.datesInMonthAavailable)
				let totalAvailability = currentMonthDates.reduce((sum, currDate) => {
					return sum + this.datesInMonthAavailable[currDate].length
				}, 0)
				if (totalAvailability == 0) {
					await this.nextMonth()
				}
				if (this.preSelectedDate) {
					// this.firstLoad = false
					this.valueDate = this.preSelectedDate
					this.selectDate([this.preSelectedDate])
					document.getElementById(`calendar__element__${this.slug}`).scrollIntoView({ behavior: 'smooth' })
				}
			},
			async fetchData() {
				const emptyData = { data: { duration_minutes: 30 } }
				try {
					const resp = await this.$axios.get('v1/booking/book/' + this.slug, { baseURL: this.apiBaseUrl })
					this.meeting = 'data' in resp && 'meeting' in resp.data ? resp.data.meeting : emptyData
				} catch (error) {
					this.$notify({
						group: '1',
						title: this.$t('shared.error.generic_title'),
						text: this.$t('shared.error.generic_text'),
						type: 'error',
					})
					this.meeting = emptyData
					if (!this.isDemo) {
						this.$sentry?.captureException(
							new Error('Could not get booking info', { extra: { error, username: this.username, slug: this.slug } })
						)
					}
				}
			},
			getDateRangeOfMonth(date) {
				const monthDate = moment(date)
				const startOfMonth = monthDate.startOf('month').format('YYYY-MM-DD')
				const endOfMonth = monthDate.endOf('month').format('YYYY-MM-DD')
				return { start: startOfMonth, end: endOfMonth }
			},
			getCacheMonthData(key) {
				const data = window.calendarCache ? window.calendarCache : null
				if (data && data[key]) {
					return data[key]
				}
				return null
			},
			setCacheMonthData(key, data) {
				if (!data) {
					window.calendarCache = null
					return
				}
				const rootData = window.calendarCache ? window.calendarCache : {}
				const objStore = {}
				objStore[key] = data
				if (rootData) {
					Object.assign(rootData, objStore)
				}
				window.calendarCache = rootData
			},
			async getMonthAvailableTimeSlots(date = new Date()) {
				const monthDateRange = this.getDateRangeOfMonth(date)
				this.loading = true
				await this.getAvailableTimeSlots(monthDateRange)
				this.loading = false
			},
			async getAvailableTimeSlots(dateRange) {
				if (this.isDemo) {
					const demoTimes = []
					for (let i = 9; i <= 17; i++) {
						const mins = ['00', '30']
						mins.forEach(min => {
							const time = moment(dateRange.start + ' ' + i + ':' + min)
							const obj = {
								startTime: time.format('hh:mm A'),
								stime: time.format('hh:mm'),
								ampm: time.format('A'),
								startDateTime: time.format('YYYY-MM-DD hh:mm A'),
								timezone: this.timezone,
							}
							demoTimes.push(obj)
						})
					}
					this.availableSlot = demoTimes
					return true
				}

				const formattedStartDate = moment(dateRange.start).format(this.dateFormat)
				if (!this.datesInMonthAavailable[formattedStartDate]) {
					const params = {
						timezone: this.timezone,
						duration: this.meeting.data.duration_minutes,
						start: dateRange.start,
						end: dateRange.end,
					}
					try {
						const resp = await this.$axios.get(`v1/booking/availability/${this.username}/${this.slug}`, {
							params,
							baseURL: this.apiBaseUrl,
						})

						this.datesInMonthAavailable = resp.data.available_slots
					} catch (error) {
						if (!this.isDemo) {
							this.$sentry?.captureException(new Error(`Could not get booking availability`), scope => {
								scope.setExtra('error', error)
								scope.setExtra('username', this.username)
								scope.setExtra('slug', this.slug)
								scope.setExtra('params', this.params)
								return scope
							})
						}
						this.availableSlot = []
					}
				}
				return true
			},
			selectTime(slot) {
				this.activeTime = slot
				this.$emit('time-selected', { slot: this.activeTime, duration: this.meeting.data.duration_minutes })
				this.step = 3
			},
			async nextMonth() {
				const date = this.valueDate ? new Date(this.valueDate) : new Date()
				this.valueDate = new Date(date.setMonth(date.getMonth() + 1, 1))
				await this.getMonthAvailableTimeSlots(moment(this.valueDate).format(this.dateFormat))
			},
			async previousMonth() {
				if (this.step === 2) {
					this.step = 1
				} else if (this.step === 3) {
					this.step = 2
				} else {
					const date = this.valueDate ? moment(this.valueDate) : moment()
					let d = new Date()
					d = new Date(d.setMonth(d.getMonth(), 1))
					d = d.setHours(0, 0, 0, 0)
					let newDate = new Date(date.month(date.month() - 1, 1))
					newDate = newDate.setHours(0, 0, 0, 0)
					if (newDate >= d) {
						this.valueDate = new Date(newDate)
						this.getMonthAvailableTimeSlots(moment(this.valueDate).format(this.dateFormat))
					}
				}
			},
			dateDisabled(date) {
				const day = new Date(date)
				const currentDate = new Date()
				currentDate.setDate(currentDate.getDate() - 1)

				// Also if on mobile_preview
				const urlParams = new URLSearchParams(window.location.search)

				return currentDate.getTime() >= day.getTime() || urlParams.get('mobile_app_viewing_preview') === 'true'
			},
			onDayCreate(dObj, dStr, fp, dayElem) {
				const date = dayElem.dateObj

				let compareDate = moment(this.valueDate)
				if (!this.valueDate) {
					compareDate = new Date()
				}
				const currentDate = new Date(compareDate)
				const currentMonth = currentDate.getMonth()
				const pickedMonth = date.getMonth()
				if (currentMonth === pickedMonth) {
					let availability = {}
					if (this.availability && Object.keys(this.availability).length !== 0) {
						availability = this.availability
					}
					if (
						this.meeting &&
						this.meeting.data &&
						this.meeting.data.availability &&
						Object.keys(this.meeting.data.availability).length !== 0
					) {
						availability = this.meeting.data.availability
					}

					let result = 'day-available '
					const slots = this.datesInMonthAavailable[moment(date).format(this.dateFormat)] || []
					if ((this.isDemo && !availability[date.getDay()]) || (!this.isDemo && slots && slots.length === 0)) {
						result += ' out-of-slot'
					}
					dayElem.className += ' ' + result
				} else {
					dayElem.className += ' ' + 'out-date'
				}

				if (dayElem.className.indexOf('day-available') === -1 || dayElem.className.indexOf('out-of-slot') !== -1) {
					dayElem.className = dayElem.className.replace('selected', '')
				}
			},
			selectDate(dates, dateStr, instance) {
				const date = dates[0]
				if (!this.firstLoad && date) {
					if (!this.preview) {
						this.$emit('date-selected', moment(date).format(this.dateFormat))
					}
					this.step = 1
					this.firstLoad = true
					return true
				}
				if (this.isDemo) {
					this.step = 2
					return true
				}
				this.activeDate = moment(date).format(this.dateFormat)
				this.$emit('date-selected', this.activeDate)
				if (this.preview) {
					// preview only offers date selection
					return true
				}
				this.availableSlot = this.datesInMonthAavailable[this.activeDate]
				this.step = 2
			},
			processOutDateRowElements() {
				if (!this.isLoading) {
					setTimeout(() => {
						// Get the container element by id
						const container = document.getElementById(`calendar__element__${this.slug}`)

						if (container) {
							// Get all elements with the class "flatpickr-day" under the container
							const allElements = Array.from(container.querySelectorAll('.flatpickr-day'))

							let count = 0
							let startIdx = 0

							// Iterate through the elements array
							for (let i = 0; i < allElements.length; i++) {
								if (allElements[i].classList.contains('nextMonthDay') && allElements[i].classList.contains('out-date')) {
									count++
									if (count === 1) startIdx = i // Mark the start of the sequence
								} else {
									if (count >= 7) {
										// Add 'hide-slot' class to the last 7 elements of the sequence
										for (let j = count - 7; j < count; j++) {
											const element = allElements[startIdx + j]
											if (!element.classList.contains('hide-slot')) {
												element.classList.add('hide-slot')
											}
										}
									}
									count = 0 // Reset the count for the next sequence
								}
							}

							// Handle the case where the last sequence reaches the end of the array
							if (count >= 7) {
								for (let j = count - 7; j < count; j++) {
									const element = allElements[startIdx + j]
									if (!element.classList.contains('hide-slot')) {
										element.classList.add('hide-slot')
									}
								}
							}
						}
					}, 0)
				}
			},
		},
		watch: {
			isLoading: {
				handler() {
					this.processOutDateRowElements()
				},
				deep: true,
				immediate: true,
			},
		},
	}
</script>
<style lang="scss" scoped>
	.w-100 {
		width: 100% !important;
	}
	.text-center {
		text-align: center !important;
	}
	.margin-t-20 {
		margin-top: 20px;
	}
	.margin-b-20 {
		margin-bottom: 20px;
	}
	.calendar__loader {
		width: 100% !important;
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		padding: 125.5px 0;
	}
	.calendar__day-slots {
		:disabled {
			cursor: default;
			opacity: 0.5;
			pointer-events: none;
		}
		.calendar_days span {
			font-size: 12px;
			margin-bottom: 10px;
			text-transform: uppercase;
			color: #404243;
			font-weight: 500;
			width: 40px;
			text-align: center;
		}
		.calendar_date span {
			width: 40px;
			height: 40px;
			border-radius: 100%;
			min-width: 40px;
			background: rgb(92 124 255/0.1);
			color: #5c7cff;
			cursor: pointer;
			font-weight: 700;
			cursor: not-allowed;
			font-size: 20px;
		}
		.calendar_date span button {
			color: #5c7cff;
			color: var(--stan-store-theme-primary-color);
			width: 100%;
			height: 100%;
		}

		.selected {
			// background: #5c7cff !important;
			background: var(--stan-store-theme-primary-color) !important;
			box-shadow: 0 5px 25px -5px rgb(83 116 255 / 50%);
		}
		.selected button {
			color: #fff !important;
		}
	}

	.disabled {
		opacity: 0.5;
		pointer-events: none;
		cursor: none;
	}

	.calendar__time-slots {
		padding: 0 15px;
		flex-wrap: wrap;
		.calendar__time-slot {
			width: 100%;
			float: left;
			.calendar__time-slot-button {
				display: flex;
				height: 46px;
				width: 100%;
				border-radius: 30px;
				background: rgb(92 124 255/0.1);
				color: var(--stan-store-theme-primary-color);
				margin: 11px 0;
				position: relative;
				float: left;
				cursor: pointer;
				font-weight: 500;
				align-items: center;
				justify-content: center;
				text-align: center;
				.calendar__time {
					font-size: 14px;
					line-height: 16px;
					display: inline-block;
				}
			}

			.ampam {
				font-size: 14px;
				font-weight: 500;
				margin-left: 3px;
				display: inline-block;
			}
			.d__flex {
				display: flex;
			}
			.selected {
				// background: #5c7cff !important;
				background: var(--stan-store-theme-primary-color) !important;
				box-shadow: 0 5px 25px -5px rgb(83 116 255 / 50%);
			}
			.selected span {
				color: #fff !important;
			}
		}
	}

	.orient-horizontal {
		.calendar__day-slots {
			.calendar_date span {
				background-color: #ffffff;
			}

			.selected {
				box-shadow: none;
			}
		}

		.calendar__time-slots {
			height: auto;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			padding: 10px 0;
			gap: 15px 40px;
			.calendar__time-slot {
				.calendar__time-slot-button {
					display: flex;
					border-radius: 0;
					padding: 15px 10px;
					height: auto;
					background-color: var(--stan-store-theme-primary-color);
					margin: 0;
					color: rgba(0, 0, 0, 0.87);
					.time,
					.ampm {
						display: block;
						margin: 0;
					}

					&:hover,
					&.selected {
						color: #ffffff;
						background-color: var(--stan-store-theme-primary-color);
						box-shadow: none;
					}
				}
			}
		}
	}
	.d-block {
		display: block !important;
	}

	@media (min-width: 440px) {
		.calendar__time-slots .calendar__time-slot .calendar__time-slot-button .calendar__time {
			text-wrap: nowrap;
		}
	}
</style>
<style lang="scss">
	.calendar__title {
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 19px;
		color: #000;
		/* identical to box height */

		letter-spacing: 0.04em;
	}
	.calendar__navigator {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 10px 0;
		button {
			border: 0;
			background-color: transparent;
		}
	}
	.calendar__subtitle {
		font-size: 13px;
		padding: 8px 15px;
		line-height: 24px;
		&.month {
			color: #292a33;
			background-color: var(--stan-store-theme-secondary-color, #f8f7fa);
			font-weight: 400;
		}
		&.duration {
			color: #19181a;
			font-weight: 500;
		}
		&.final-time {
			text-align: center;
			font-weight: 600;
			font-size: 15px;
			color: #fff;
			padding: 13px 25px;
			background-color: var(--stan-store-theme-primary-color) !important;
			margin: 0;
		}
	}
	.calendar__timezone {
		font-size: 14px;
		padding-top: 5px;
		padding-bottom: 10px;
		line-height: 24px;
	}
	.calendar {
		.flatpickr-input,
		.b-calendar-nav,
		header,
		footer {
			display: none !important;
		}
		.flatpickr-calendar {
			border: none;
			box-shadow: none;
			width: 100%;
			background: transparent;
			border-radius: 10px;
			padding: 10px;

			.flatpickr-months {
				display: none;
			}
			.flatpickr-innerContainer {
				width: 100%;
				.flatpickr-rContainer {
					width: 100%;
					.flatpickr-days {
						width: 100%;
						.selected,
						.selected:hover {
							background-color: var(--stan-store-theme-primary-color, var(--stan-user-primary-color, var(--stan-store-default-color)));
							border-color: var(--stan-store-theme-primary-color, var(--stan-user-primary-color, var(--stan-store-default-color)));
							color: var(--stan-store-theme-button-text-color, #ffffff);
						}
						.dayContainer {
							width: 100%;
							max-width: unset;
							min-width: unset;
							gap: 5px;
							justify-content: center;
							padding: 2.5px;
							display: grid;
							grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
							span {
								display: flex;
								align-items: center;
								line-height: unset;
								height: auto;
								aspect-ratio: 1;
								width: min(39px, 100%);
								margin: auto;
							}
						}
					}
				}
			}
		}
		.calendar__time-slot-button {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.day-available {
			background-color: var(--stan-store-theme-secondary-color, #edf2ff);
			color: var(--stan-store-theme-button-text-color, rgba(0, 0, 0, 0.87));
			&.out-of-slot {
				cursor: not-allowed;
				background-color: transparent !important;
				border-color: transparent !important;
				pointer-events: none;
			}
		}
		.selected,
		.selected:hover {
			background-color: var(--stan-store-theme-primary-color, var(--stan-user-primary-color, var(--stan-store-default-color)));
			border-color: var(--stan-store-theme-primary-color, var(--stan-user-primary-color, var(--stan-store-default-color)));
			color: var(--stan-store-theme-button-text-color, #ffffff);
		}
		.day-off,
		.out-date {
			cursor: not-allowed !important;
			background-color: transparent !important;
			pointer-events: none;
		}
		.hide-slot {
			display: none !important;
		}
	}
	@-webkit-keyframes slide-in-fwd-bottom {
		0% {
			-webkit-transform: translateZ(-140px) translateY(80px);
			transform: translateZ(-140px) translateY(80px);
			opacity: 0;
		}
		100% {
			-webkit-transform: translateZ(0) translateY(0);
			transform: translateZ(0) translateY(0);
			opacity: 1;
		}
	}
	@keyframes slide-in-fwd-bottom {
		0% {
			-webkit-transform: translateZ(-140px) translateY(80px);
			transform: translateZ(-140px) translateY(80px);
			opacity: 0;
		}
		100% {
			-webkit-transform: translateZ(0) translateY(0);
			transform: translateZ(0) translateY(0);
			opacity: 1;
		}
	}

	.slide-in-fwd-bottom {
		-webkit-animation: slide-in-fwd-bottom 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
		animation: slide-in-fwd-bottom 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	}

	.calendar.preview {
		padding-top: 10px;
		border-radius: 15px;
		padding: 20px;
		margin-top: 30px;
		margin-bottom: 15px;
		background: rgba(21, 21, 21, 0.05);
		font-family: 'Inter', sans-serif !important;
		color: #323232 !important;
		text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);

		.calendar__title,
		.calendar__timezone {
			display: none !important;
		}

		.calendar__navigator {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 0px !important;
			margin-bottom: 18px !important;
			button {
				border: 0;
				background-color: transparent;
				svg {
					max-height: 14px;
					filter: drop-shadow(1px 1px 5px rgb(0 0 0 / 0.2));
				}
			}
		}
		.calendar__subtitle {
			font-size: 13px;
			padding: 8px 15px 4px 15px;
			line-height: 24px;
			font-family: 'Inter', sans-serif !important;
			overflow: visible;

			&.month {
				font-weight: 700;
				font-size: 14px;
				line-height: 17px;
				background-color: transparent;
			}
		}
		.calendar__timezone {
			font-size: 14px;
			padding-top: 5px;
			padding-bottom: 10px;
			line-height: 24px;
			font-style: italic;
		}
		.flatpickr-input,
		.b-calendar-nav,
		header,
		footer {
			display: none !important;
		}
		.flatpickr-calendar {
			border: none;
			box-shadow: none;
			width: 100%;
			background: transparent;

			span {
				font-family: 'Inter', sans-serif !important;
			}
			.flatpickr-months {
				display: none;
			}
			.flatpickr-weekdays {
				margin-bottom: 3px;
				overflow: visible;
				.flatpickr-weekdaycontainer {
					overflow: visible;
					.flatpickr-weekday {
						font-family: 'Inter';
						font-style: normal;
						font-weight: 600;
						font-size: 10px;
						line-height: 12px;
						text-align: center;
						letter-spacing: 0.01em;
						color: #323232 !important;
						overflow: visible;
					}
				}
			}
			.flatpickr-innerContainer {
				width: 100%;
				.flatpickr-rContainer {
					width: 100%;
					.flatpickr-days {
						width: 100%;
						.dayContainer {
							width: 100%;
							max-width: unset;
							min-width: unset;
							gap: 10px;
							justify-content: center;
							padding: 5px;
							display: grid;
							grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
							span {
								display: flex;
								align-items: center;
								height: auto;
								max-height: 30px;
								aspect-ratio: 1;
								width: min(30px, 100%);
								margin: auto;

								font-weight: 400;
								font-size: 12px;
								line-height: 15px;
								text-align: center;
								letter-spacing: 0.01em;
								color: black;
							}
						}
					}
				}
			}
		}
		.day-available {
			background-color: transparent;
			color: black;
			border-color: black !important;
			box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
			&.out-of-slot {
				cursor: not-allowed;
				background-color: transparent !important;
				pointer-events: none;
				border-color: transparent !important;
				box-shadow: none;
			}
		}
		.selected,
		.selected:hover {
			// background-color: var(--stan-store-theme-primary-color, var(--stan-user-primary-color, var(--stan-store-default-color)));
			background-color: black;
			// border-color: var(--stan-store-theme-primary-color, var(--stan-user-primary-color, var(--stan-store-default-color)));
			border-color: black;
			font-family: 'Inter';
			font-style: normal;
			font-weight: 700;
			font-size: 12px;
			line-height: 15px;
			text-align: center;
			letter-spacing: 0.01em;
			color: #ffffff !important;
		}
		.day-off,
		.out-date {
			opacity: 0.5;
			cursor: not-allowed !important;
			background-color: transparent !important;
			color: black;
			pointer-events: none;
		}
		.hide-slot {
			display: none !important;
		}
		.calendar__loader {
			padding: 129.5px 0;
		}
	}
</style>
